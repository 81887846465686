export enum APP_ROUTES {
  HOME = "",
  LOGIN = "/login",
  SIGN_UP = "/sign-up",
  RESET_PASSWORD = "/reset-password",
  CHANGE_PASSWORD_RESET = "/password/reset/confirm/:uid/",
  NEW_PASSWORD_REQUIRED = "/password/new/",
  CHANGE_PASSWORD = "/password/change/",
  DASHBOARD = "/dashboard",
  LATEST_UPDATES = "/latest-updates",

  COMPANIES = "/companies",
  INVESTORS = "/investors",
  FUNDING_ROUND = "/funding-round/:fundingRoundId",
  FUNDING_ROUND_NEW = "/funding-round/",
  FUNDING_ROUNDS = "/funding-rounds",
  FUNDING = "/funding/:fundingId",

  PARTNERSHIPS = "/partnerships",
  GRAPH_PARTNERSHIPS = "/partnerships/graph",
  GRAPH_PARTNERSHIPS_ENTITY = "/partnerships/graph/:entityId",

  GROUPS_AND_CENTERS = "/groups-and-centers",
  UNIVERSITIES = "/universities",
  NATIONAL_LABS = "/national-labs",

  ENTERPRISE_USERS = "/enterprise-users",

  GOVERNMENTS = "/governments",

  GOVERNMENT_FUNDINGS = "/government-fundings",
  GOVERNMENT_FUNDINGS_MAP = "/government-fundings/map",
  GOVERNMENT_FUNDING = "/government-funding/:entityId",
  GOVERNMENT_FUNDING_NEW = "/government-funding/",

  PARTNERSHIP = "/partnership/",
  PARTNERSHIP_EDIT = "/partnership/:partnershipId",

  REPORTS = "/reports",
  INSIGHTS = "/insights",
  TAXONOMY = "/taxonomy",
  NEWS = "/news",
  FORUM = "/forum",
  OFFICE = "/office",
  PROFILE = "/profile",
  COMPANY_PROFILE = "/company/:entityId/profile",
  COMPANY_FUNDING = "/company/:entityId/funding",
  COMPANY_PATENTS = "/company/:entityId/patents",

  CONSORTIUMS = "/consortiums",
  CONSORTIUMS_MAP = "/consortiums/map",
  CONSORTIUM_PROFILE = "/consortium/:entityId/profile",
  CONSORTIUM_MEMBERS = "/consortium/:entityId/members",

  INVESTOR_PROFILE = "/investor/:entityId/profile",
  INVESTOR_ARTICLE = "/investor/:entityId/articles",

  UNIVERSITY_PROFILE = "/university/:entityId/profile",

  NATIONAL_LABS_PROFILE = "/national-lab/:labId/profile",

  GOVERNMENT_ENTITIES_PROFILE = "/government/:entityId/profile",

  GROUPS_AND_CENTERS_PROFILE = "/groups-and-center/:entityId/profile",

  ENTERPRISE_USERS_PROFILE = "/enterprise-user/:entityId/profile",

  CONTACT = "/contact",
  CONFIRM_REGISTRATION = "/auth/users/activate/:uid/:token",
  SEARCH_PAGE = "/search",

  ENTITY = "/entity/:entityId",
  ENTITY_NEW = "/entity/",

  ADMIN_DASHBOARD = "/admin-dashboard",
  ADMIN_DASHBOARD_ENTITIES = "/admin-dashboard/entities",
  ADMIN_DASHBOARD_VERTICAL_PROFILES = "/admin-dashboard/vertical-profiles",
  ADMIN_DASHBOARD_ROUNDS = "/admin-dashboard/rounds",
  ADMIN_DASHBOARD_GOV_ROUNDS = "/admin-dashboard/gov-rounds",
  ADMIN_DASHBOARD_DELETED_ENTITIES = "/admin-dashboard/deleted-entities",
  ADMIN_DASHBOARD_DELETED_USE_CASES = "/admin-dashboard/deleted-use-cases",
  ADMIN_DASHBOARD_DELETED_ROUNDS = "/admin-dashboard/deleted-rounds",
  ADMIN_DASHBOARD_DELETED_GOV_ROUNDS = "/admin-dashboard/deleted-gov-rounds",
  ADMIN_DASHBOARD_DELETED_PARTNERSHIPS = "/admin-dashboard/deleted-partnerships",
  MANAGE_ADMINS = "/manage-admins",

  GRAPHING_TOOL = "/graphing-tool",
  GRAPHING_TOOL_ROUNDS = "/graphing-tool-rounds",
  ANALYSIS_TOOL = "/analysis-tool",
  GRAPH_EXPLORER = "/graph-explorer",
  GRAPH_EXPLORER_ENTITY = "/graph-explorer/:entityId",
  ROAD_MAP = "/roadmap",
  SUMMARY_MAP = "/map",
  HEATMAP = "/heatmap",

  MARKET_MAP = "/market-map",
  MARKET_MAP_INVESTORS = "/market-map/investors",
  MARKET_MAP_USERS = "/market-map/users",

  QCAAS = "/qcaas",
  QPU = "/qpu",

  SEARCH_ENGINE_HOME = "/news-feed",
  SEARCH_ENGINE = "/search-engine",
  ANALYSIS_GRAPH = "/analysis-graph",
  AI_SUMMARY = "/ai-summary",
  SEARCH_ENGINE_STORIES = "/stories",

  MEDIA_MONITORING = "/media-monitoring",
  MEDIA_MONITORING_ANALYSIS = "/media-monitoring/analysis",

  USE_CASE = "/use-case/:useCaseId",
  USE_CASE_NEW = "/use-case",
  USE_CASES = "/use-cases",

  BULK_UPLOAD = "/bulk-upload",

  // RESEARCH = "/research",
  PATENTS = "/patents",
  PATENTS_LIST_VIEW = "/patents/list",
  PATENTS_STATS = "/patents/stats",

  RESEARCH_PAPERS = "/research-papers",
  RESEARCH_PAPERS_LIST_VIEW = "/research-papers/list",
  RESEARCH_PAPERS_STATS = "/research-papers/stats",

  NOT_FOUND = "/page-not-found",
}

export default APP_ROUTES;
